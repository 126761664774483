<template>
  <div class="loading"></div>
</template>

<style>
.loading {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid rgba(0,0,0,.2 );
  border-radius: 50%;
  border-top-color: rgba(0,0,0,.4 );
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50%);
  top: calc(50%);
  position: fixed;
  z-index: 1;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
</style>

<script>
import LiffChecker from "@/utils/LiffChecker";

export default {
  mounted() {
    const checker = new LiffChecker(this.$route.params.org_code)
    checker.bindCheck()
  }
}
</script>
